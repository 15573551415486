import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import { Grid } from '@mui/material'
import Spinner from 'react-spinner-material'
import Sidebar from '../components/sidebar'
import Layout from '../components/layout'
import Seo from '../components/seo'
import PageHeader from '../components/pageheader'

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'
const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};
class LegalsPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      viewportWidth: isClient ? window.innerWidth : 0,
      loading: true,      
      orderitems: [],
    }
    this.changeCookie = this.changeCookie.bind(this);
    this.switchPage = this.switchPage.bind(this);
  }
  switchPage(pagename){
    this.setState({
        loading: true,
     });
     navigate(pagename)
  };
  changeCookie(cookieName, newState) {
    const { cookies } = this.props
    cookies.set(cookieName, newState, { path: '/' })
    this.setState({ cookieName: newState })
  }
  updateWindowDimensions = () => {
    // console.log(window.innerWidth)
    this.setState({ viewportWidth: window.innerWidth })
  }  
  componentDidMount() {
    // console.log(this.props.allCookies)
    this.changeCookie('lastpage', '/')
    // if (!this.props.allCookies.firstload) {            
    //   setTimeout(() => {
    //     navigate('/loading')
    //   }, 500)
    // }
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
    if(this.props.allCookies.orderitems) {
      this.setState({
        orderitems: this.props.allCookies.orderitems
        
      })
    }
    this.setState({ loading: false })
  }
  componentWillUnmount() {
    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    const siteTitle = "Ready Made Meals"
    const keywords = ["ready made meals", "healthy", "dinner", "family", "building futures", "montessori"]
    const pageName = 'Terms & Conditions'
    
    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }
    var spacercol = 1;
    var contentcol = 10;
    if(pagewidth) {
      var spacercol = 0;
      var contentcol = 12;
    }

    return (
      <React.Fragment>
        <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <Sidebar switchPage={this.switchPage} orderitems={this.state.orderitems} removeitemfromorder={this.removeitemfromorder}/>
          {this.state.loading ? (
            <div className="spinneroverlay">
              <div className="spinnercontainer">
                <Spinner
                  radius={100}
                  color={'#ff4c76'}
                  stroke={2}
                  visible={true}
                />
              </div>
            </div>
          ) : (
            <div />
          )}
          
          <div className="page_wrapper generalpage_wrapper">
            <PageHeader pagewidth={pagewidth.matches} pagetitle={pageName} />
            <Grid container spacing={0} >
              <Grid item xs={12} >
                <Grid container spacing={0} >
                  <Grid item xs={spacercol} />
                  <Grid item xs={contentcol} >
                    <div className='generalpage_content'>                      
                      <div className="contentcontainer">                      
                        <Grid container spacing={0}>                          
                          <Grid item xs={12}>                            
                            <ul>
                              <li>The provision of Ready Made Meals is currently in a trial phase.  We welcome all feedback on the processes and the meals. </li>
                              <li>Once an order has been placed, payment must be made in full to successfully submit an order. </li>
                              <li>Orders are non-refundable even if not collected, and cannot be cancelled after payment is made.</li>
                              <li>Orders will only be able to be submitted during the timeframe allocated. An email will be sent out each week advising the opening and closing deadlines of the ordering for the following week.</li>
                              <li>Failure to pick up on the evening when the order is ready will result in disposal. Meals will not be kept or available for pick up the following day due to food safety and council requirements.</li>
                              <li>Building Futures takes no responsibility for the meals once they are collected.  The transport, reheating and consumption are the responsibility of the individual.  Building Futures will provide guidelines for the transport, reheating and consumption of the meals only.</li>
                            </ul>                            
                          </Grid>                          
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(LegalsPage)
